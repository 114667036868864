.App {
  text-align: center;
  z-index: -100;
}

body {
  font-family: 'Montserrat' !important;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.welcome-text {
  text-align: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 2rem;
}


.welcome-button {
  left: 30%;
  position: absolute;
  top: 35vh;
  width: 40vw !important;
  min-width: 180px;
  color: white;
  font-size: 24px;
  z-index: 10;
}

.button-59 {
  border-radius: 20px;
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 1vh;
}

.button-59:focus {
  color: #171e29;
}

.button-59:hover {
  border-color: rgb(255, 255, 255);
  background-color: #000;
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
}

.button-59:active {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

@media (min-width: 768px) {
  .button-59 {
    min-width: 170px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.about_text {
  position: relative;
  font-size: 5em;
  font-weight: 700;
  top: 5vh;
  margin-top: 50vh;
  margin-bottom: 10vh;
  z-index: 10;
  color: white;
  font-family: 'Montserrat';
}

.underline {
  position: relative;

}

.underline-contact {
  position: relative;

}

.underline-contact::before {
  content: '';
  border-bottom: 18px solid #ff4d5a;
  width: 3.5em;
  display: block;
  margin: 0 auto;
  position: relative;
  left: .5em;
  top: 5.5rem;
  z-index: -1;
}

.underline-projects {
  position: relative;

}

html {
  background: black;
  z-index: -99999;
}

.underline-projects::before {
  content: '';
  border-bottom: 18px solid #ff4d5a;
  width: 3.5em;
  display: block;
  margin: 0 auto;
  position: relative;
  left: .5em;
  top: 5.5rem;
  z-index: -1;
}

.underline::before {
  content: '';
  border-bottom: 18px solid #ff4d5a;
  width: 13rem;
  display: block;
  margin: 0 auto;
  position: relative;
  left: 3rem;
  top: 5.5rem;
  z-index: -1;
}

.user-icon {
  font-size: 10rem;
  background-image: linear-gradient(130deg, #504dff 25%, #ff4d5a 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.user-icon-wrapper {
  margin-bottom: 2rem;

}

.about-info {
  white-space: initial;
  word-wrap: break-word;
  color: white;
  font-weight: 600;
  font-size: large;
  text-align: left !important;
  left: 10vw;
}

.tools-list {}

.tools {
  opacity: 1;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100px;
  width: 100px;
  border: double 6px transparent;
  border-image-slice: 2;
  border-width: 3px;
  border-left-width: 1px;
  background-image: linear-gradient(#1a1a1a, #1a1a1a), linear-gradient(130deg, #504dff 25%, #ff4d5a 100%);
  border-radius: 10px;
  background-clip: content-box, border-box;
  transition: transform 0.2s, opacity 1.2s;
  z-index: 3;
  margin-bottom: 1rem;
  margin-left: 1rem;
  min-width: 150px !important;
  max-width: 150px !important;
}

.tools-img {
  height: 5vh;
}

.spacer {
  position: relative;
  margin-top: 10vh;
}

.text-area {
  width: 100%;
  resize: none;
  border: none;
  color: white;
  background-color: #282828;
  font-size: 1.6rem;
  border-left: 2px solid transparent;
  border-radius: 0;
  margin-top: 1vh;
}

.message-text-area {
  height: 200px;
}

.contact-button {
  width: 30vw;
  resize: none;
  border: none;
  color: white;
  background-color: #ff4d5a;
  font-size: 1.6rem;
  border-left: 2px solid transparent;
  border-radius: 0;
  margin-top: 1vh;
  outline: none;
  transition: .5s ease-in-out;
}

.contact-button:hover {
  background-color: #c1313b;
  width: 40vw;
}

.contact-form {
  justify-content: center;
  margin-top: -20vh;
}

.project-text {
  color: white;
  text-align: right;
  width: 100%;
  font-weight: 600;
}

.project-text-right {
  color: white;
  text-align: left;
  width: 100%;
  font-weight: 600;

}

.stuffio-website {
  border-image: linear-gradient(130deg, #504dff 25%, #ff4d5a 100%) 30;
  border-width: .3rem;
  border-style: solid;
  max-width: 100%;
  transition: all 0.2s;
}

.stuffio-website:hover {
  max-width: 95%;
  border-width: 1.5rem;
}

.stuffio-ios {
  border-image: linear-gradient(130deg, #504dff 25%, #ff4d5a 100%) 30;
  border-width: .3rem;
  border-style: solid;
  transition: all 0.2s;
}

.stuffio-ios:hover {
  max-width: 90%;
  border-width: 1.5rem;
}

.market-algo {
  border-image: linear-gradient(130deg, #504dff 25%, #ff4d5a 100%) 30;
  border-width: .3rem;
  border-style: solid;
  transition: all 0.2s;
  max-width: 100%;
}

.market-algo:hover {
  max-width: 95%;
  border-width: 1.5rem;
}

.smashnews {
  border-image: linear-gradient(130deg, #504dff 25%, #ff4d5a 100%) 30;
  border-width: .3rem;
  border-style: solid;
  transition: all 0.2s;
  transition: all 0.2s;
  max-width: 100%;
}

.project-button {
  width: 40%;
  min-width: 1px;
  resize: none;
  border: none;
  color: white;
  background-color: #ff4d5a;
  font-size: 1.6rem;
  border-left: 2px solid transparent;
  border-radius: 0;
  margin-top: 1vh;
  outline: none;
  transition: .5s ease-in-out;
  margin-top: 5vh;
}

.project-github {
  width: 50%;
  min-width: 1px;
  resize: none;
  border: none;
  color: white;
  background-color: #ff4d5a;
  font-size: 1.6rem;
  border-left: 2px solid transparent;
  border-radius: 0;
  margin-top: 1vh;
  outline: none;
  transition: .5s ease-in-out;
  margin-top: 5vh;
  margin-left: 25%;
}

.project-link {

  color: white;

  font-size: 1.6rem;
  border-left: 2px solid transparent;
  border-radius: 0;
  margin-top: 1vh;
  outline: none;
  margin-top: 5vh;
  text-decoration: none;
}

.project-github:hover {
  background-color: #c1313b;
  width: 60%;
}

.project-button:hover {
  background-color: #c1313b;
  width: 50%;
}